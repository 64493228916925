/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Fullmap, FullmapWrap, ColumnWrap, Title, Text, YouTube, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pro rodiče"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuálně</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222); text-decoration: underline;\">Pro <span style=\"text-decoration: underline;\">rodiče</span></span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/fotogalerie"} target={""} content={"<span style=\"caret-color: rgb(221, 116, 15); color: rgb(221, 116, 15);\">Fotogalerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69);\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify pb--80 pr--0 pt--80" style={{"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Fullmap className="--style2" name={"xrq5c45sffc"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <ColumnCover name={"8nlzg5iq4eb"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" style={{"backgroundColor":"rgba(222,222,222,1)"}} menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Pro rodiče</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style3">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--center" style={{"maxWidth":429}} href={"/pro-rodice"} target={""} content={"<span style=\"text-decoration: underline;\">Informace</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/stravovani"} target={""} content={"Stravování"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/organizace"} target={""} content={"Organizace"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/formulare"} target={""} content={"Formuláře"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--08 pt--60" name={"hexovz5xot"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"<span style=\"font-weight: bold; text-decoration-line: underline;\">Nácvik správného úchopu tužky</span>"}>
              </Title>

              <Text className="text-box" content={"Víte, jak správně by měl váš školák držet tužku, když píše nebo maluje? Už mezi předškoláky je mnoho dětí, kteří trpí grafomotorickými potížemi. Těžkopádné a křečovité písmo či nezájem o kreslení jsou projevy, které by mohly rodiče upozornit na to, že je potřeba dítěti pomoci s nácvikem správného úchopu psací potřeby. <br>Pojďte si správné držení tužky procvičit s námi."}>
              </Text>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/spravny-uchop-tuzky-web.pdf"} content={"Správný úchop tužky"} document={"f14e5f928d524afd9c88846427abb886"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"3dI7kicGP_A"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"vv742er5au"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--20 pt--30" name={"btftn6scy7"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--24" content={"<span style=\"text-decoration-line: underline;\"><a href=\"https://cdn.swbpg.com/d/13139/jak-pripravit-dite-na-vstupem-do-ms.pdf\" style=\"font-weight: bold;\">Jak připravit dítě na vstup do MŠ</a>&nbsp;</span><br><br>Užitečné rady a tipy pro nově nastupující děti do mateřské školy<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}